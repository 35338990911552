import React from "react";
import Helmet from "react-helmet";
 

const SEO = (props) =>  {
      const title = props.title+'' ;
      const description = props.description+'';
      const url = props.url+'';
      const image = props.image+'';

      return (
        <Helmet
          htmlAttributes={{  lang: "en",  }}
          title={title}
          titleTemplate={`%s - ${title}`}
        >
          <meta  name="title"  content={title} />
          <meta  name="description" content={description} />
          <meta name="url" content={url} />
          <meta name="image" content={image} />
          
          <meta property="og:title"  content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={image} />
          <meta property="og:type" content="website" />

          <meta property="twitter:title"  content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:creator"  content="junald" />
 
     
          
          {props.children}
        </Helmet>
      );
  }

 
export default SEO;

 